import { computed, ref } from '@vue/composition-api';
import { useLocalizationStore } from '@/modules/builder/store/localizationStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';

export const variableTypes = {
    VARIABLE: 'variable',
    TRANSLATION_KEY: 'translation-key'
};

const selectedVariableType = ref(variableTypes.VARIABLE);

export const useVariableTreePopup = () => {
    const moduleStore = useModuleStore();
    const localizationStore = useLocalizationStore();

    const variableList = computed(() => selectedVariableType.value === variableTypes.VARIABLE ? moduleStore.variables : localizationStore.moduleTranslations);

    return {
        selectedVariableType,
        variableList
    };
};
